<template>
  <div>
    <IndexSlider
      class="mb-l"
      :slides="slides"
      :pending="bannersPending" />
    <h1 class="text-size-h2 text-bold mb-m">
      {{ $metaInfo.title }}
    </h1>
    <div v-if="pending">
      <BaseSpinner brand />
    </div>
    <div v-else>
      <div
        v-if="list.length"
        class="row cards-list achievements-list">
        <div
          v-for="item in list"
          :key="item.id"
          class="col-lg-6 col-md-12 col-sm-12 col-xs-12 cards-list__item achievements-list__item">
          <AchievementCard :achievement="item" />
        </div>
      </div>
      <div v-else>
        <p>У вас пока нет достижений текущего сезона</p>
      </div>
    </div>
  </div>
</template>

<script>
import IndexSlider from "@/components/index-slider/IndexSlider";
import AchievementCard from "@/components/user/AchievementCard";
import { mapGetters } from "vuex";
import { calconApiRequest } from "@/services/api";
import { FINAL_STAGE, INDIVIDUAL_STAGE, TEAM_STAGE } from "@/constants";
import { mapCalconAchievement } from "@/utils/achievements";
const STAGE_LABELS = {
  [INDIVIDUAL_STAGE]: "Индивидуальный этап",
  [TEAM_STAGE]: "Командный этап",
  [FINAL_STAGE]: "Финал",
};
export default {
  name: "UserAchievements",
  components: {
    IndexSlider,
    AchievementCard,
  },
  metaInfo() {
    return {
      title: "Достижения текущего сезона",
    };
  },
  data() {
    return {
      pending: false,
      banners: [],
      bannersPending: false,
      achievements: [],
    };
  },
  computed: {
    ...mapGetters({
      notUsedPrivileges: "participant/notUsedPrivileges",
      user: "user/user",
    }),
    slides() {
      const { notUsedPrivileges, banners } = this;
      const result = [];
      if (notUsedPrivileges?.length) {
        notUsedPrivileges.forEach((n) => {
          const slide = {
            type: "privilege",
            title: "Привилегия",
            score: n.score,
            privilege: n.id,
            pass: !!n.pass_through,
            finalPass: n.final_pass,
            id: `user-priv-${n.id}`,
          };
          // если привилегия про баллы
          if (n.score) {
            slide.title = "Дополнительные баллы";
          } else if (n.pass_through) {
            slide.title = "Переход в следующий этап";
          } else if (n.final_pass) {
            slide.title = "Пропуск в финал";
          }
          result.push(slide);
        });
      }

      if (banners.length) {
        return [
          ...result,
          ...banners.map((n) => {
            return {
              type: "banner",
              ...n,
            };
          }),
        ];
      }
      return result;
    },
    profiles() {
      return this.$store.state.profile.profiles;
    },
    userProfiles() {
      return this.$store.state.participant.profiles.filter((n) => !n.rejected);
    },
    /**
     * Получаем все события из выбранных профилей,
     * по которым нужно запросить достижения
     */
    events() {
      const { userProfiles, profiles } = this;
      return userProfiles.reduce((acc, n) => {
        const profile = profiles[n.profile_id];
        if (profile) {
          const stages = profile?.steps.filter((s) => s.stage === n.stage);
          acc = [...acc, ...stages.map((stage) => stage.talent_event_id)];
        }
        return acc;
      }, []);
    },

    list() {
      const { achievements, profiles } = this;
      if (!achievements.length) return [];
      const steps = Object.values(profiles).reduce((acc, profile) => {
        acc = [
          ...acc,
          ...profile.steps.map((n) => {
            return {
              ...n,
              profile_id: profile.id,
            };
          }),
        ];
        return acc;
      }, []);

      return achievements.reduce((acc, achievement) => {
        const event_id = +achievement.event_id;
        const step = steps.find((s) => +s.talent_event_id === event_id);
        if (step) {
          acc.push({
            role: achievement.role?.name,
            diploma_link: achievement.diploma_link,
            document: achievement.document,
            id: achievement.id,
            event_id,
            event_title: achievement.event_title,
            profile_id: step.profile_id,
            stage: STAGE_LABELS[step.stage],
            profile_icon: profiles[step.profile_id]?.icon,
            profile_title: profiles[step.profile_id]?.title,
            role_id: achievement.role?.id,
          });
        }
        return acc;
      }, []);
    },
  },
  created() {
    this.getBanners();
    this.getAchievements();
  },
  methods: {
    async getBanners() {
      this.bannersPending = true;
      try {
        const data = await this.$store.dispatch("getBanners");
        this.banners = data.items;
      } catch (error) {
        // do nothing
      }
      this.bannersPending = false;
    },
    async getAchievements() {
      this.pending = true;
      try {
        const { data } = await calconApiRequest({
          method: "GET",
          url: `/api/users/me/achievements/`,
          params: {
            events: this.events.join(","),
            limit: 100,
            offset: 0,
          },
        });
        this.achievements = data.results.map(mapCalconAchievement);
      } catch (error) {
        this.showErrorModal({
          content: "Не удалось получить данные о достижениях",
          message: error.message,
        });
      }
      this.pending = false;
    },
  },
};
</script>

<style lang="less" scoped>
.achievements-list {
  --grid-gutter-width: 6px;

  &__item {
    margin-bottom: 6px;
  }
}
</style>
